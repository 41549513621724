
import { defineComponent, computed } from 'vue';

import intervalToDuration from 'date-fns/intervalToDuration';

import Badge from '@/components/Badge/Badge.vue';
import Button, { BUTTON_THEMES } from '@/components/Button/Button.vue';
import RImageSrcset from '@/components/RImageSrcset/RImageSrcset.vue';

import TimeCounter from './TimeCounter.vue';

export default defineComponent({
  components: {
    Badge,
    Button,
    RImageSrcset,
    TimeCounter,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    points: {
      type: Number,
      default: null,
    },
    duration: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    startsOn: {
      type: Date,
      default: null,
    },
    classUrl: {
      type: String,
      default: '',
    },
    noImgFilter: {
      type: Boolean,
      default: false,
    },
    flipped: {
      type: Object,
      required: false,
      default: () => ({
        sm: false,
        lg: false,
      }),
    },
  },
  setup(props) {
    const remainingTime = computed(() => {
      const { months, days, hours, minutes } = intervalToDuration({
        start: new Date(),
        end: props.startsOn,
      });
      return {
        days: days + months * 30,
        hours: hours,
        minutes: minutes,
      };
    });
    return {
      BUTTON_THEMES,
      remainingTime,
    };
  },
});
