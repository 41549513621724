
import { useStore } from 'vuex';
import { defineComponent } from 'vue';
import Button, { BUTTON_THEMES } from '@/components/Button/Button.vue';
import AcademyRegularClassTeaser from '@/modules/academy/components/AcademyRegularClassTeaser/AcademyRegularClassTeaser.vue';

export default defineComponent({
  components: {
    Button,
    AcademyRegularClassTeaser,
  },
  setup() {
    const headerImages = {
      sm: require('@/assets/images/together/togetherdo-sm.jpg'),
      lg: require('@/assets/images/together/togetherdo-lg.jpg'),
      xl: require('@/assets/images/together/togetherdo-xl.jpg'),
    };
    const teaserOneImages = {
      mobile: {
        x1: require('@/assets/images/together/mural/sm.png'),
        x2: require('@/assets/images/together/mural/sm@2x.png'),
        x3: require('@/assets/images/together/mural/sm@3x.png'),
      },
      desktop: {
        x1: require('@/assets/images/together/mural/lg.jpg'),
        x2: require('@/assets/images/together/mural/lg.jpg'),
        x3: require('@/assets/images/together/mural/lg.jpg'),
      },
      alt: 'Das war DAS große Highlight am Together DAY.',
    };
    const teaserTwoImages = {
      mobile: {
        x1: require('@/assets/images/together/product/sm/img.jpg'),
        x2: require('@/assets/images/together/product/sm/img@2x.jpg'),
        x3: require('@/assets/images/together/product/sm/img@3x.jpg'),
      },
      desktop: {
        x1: require('@/assets/images/together/product/lg/img.png'),
        x2: require('@/assets/images/together/product/lg/img.png'),
        x3: require('@/assets/images/together/product/lg/img.png'),
      },
      alt: 'Das war DAS große Highlight am Together DAY.',
    };

    const store = useStore();
    const userId = store.getters['authModule/userId'];
    const {
      headers: { 'x-login-token': token },
    } = store.getters['authModule/authHeaders'];

    return {
      token,
      userId,
      headerImages,
      teaserOneImages,
      teaserTwoImages,
      BUTTON_THEMES,
    };
  },
});
