
import { defineComponent, computed } from 'vue';
export default defineComponent({
  props: {
    amount: {
      type: Number,
      required: true,
    },
    unitType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      amountWithLeadingZero: computed(() => props.amount.toString().padStart(2, '00')),
    }
  }
});
